import { inject, Injectable } from '@angular/core';
import { TeledepositApiService } from '@features/teledeposit/api/teledeposit.api.service';
import {
  BehaviorSubject,
  combineLatest,
  first,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import { DepositForm } from '../models/deposit-form';

@Injectable()
export class TeledepositService {
  private readonly teledepositApiService = inject(TeledepositApiService);

  private readonly imagesSubject = new BehaviorSubject<File[]>([]);
  private readonly depositFormSubject = new BehaviorSubject<DepositForm>({});

  readonly images$ = this.imagesSubject.asObservable();
  readonly depositForm$ = this.depositFormSubject.asObservable();
  readonly depositInfo$ = this.teledepositApiService
    .getDepositInfo()
    .pipe(shareReplay(1));

  readonly areImagesValid$ = this.images$.pipe(
    map((images) => images.length > 0),
  );
  readonly isDepositFormValid$ = of(true);

  readonly isValid$ = combineLatest([
    this.areImagesValid$,
    this.isDepositFormValid$,
  ]).pipe(map(([images, form]) => images && form));

  public saveImages(images: File[]) {
    this.imagesSubject.next(images);
  }

  public saveAnswers({ patientForm, formAnswers }: DepositForm) {
    this.depositFormSubject.next({ patientForm, formAnswers });
  }

  public createTeledeposit(): Observable<void> {
    return combineLatest([this.imagesSubject, this.depositFormSubject]).pipe(
      first(),
      switchMap(([images, {formAnswers}]) => {
        return this.teledepositApiService.createDepositSequence(
          images,
          {answers: formAnswers ?? {}},
        );
      }),
    );
  }

  clear() {
    this.imagesSubject.next([]);
    this.depositFormSubject.next({});
  }
}
